export const state = {
    tenant: localStorage.getItem('tenant') || '',
};

export const getters = {
    getTenant: state => {
        return localStorage.getItem('tenant') || state.tenant;
    },
};

export const mutations = {
    setTenant: (state, id) => {
        state.tenant = id;
        localStorage.setItem('tenant', id);
    },
};
