import axios from '../helpers/api';
import helper from '../helpers/custom/index';
import db from '@/services/pouchDb';
import moment from 'moment';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { version } from './../../package.json';
import _ from 'lodash';
let lastUserGPSData = {};

//userActivityLogger.servide
export const userActivityLoggerService = {
    createTracking,
    getDeviceBattery,
    getGPSCoordinates,
    getActivityLogData,
    getGeoAddress,
    getGeoLat,
    getLoginHistoryCount,
};

async function getGeoAddress(lat = '0', lng = '0') {
    // normalize coordinates
    let nrmLat = helper.normalizeCoordinate(lat);
    let nrmLng = helper.normalizeCoordinate(lng);
    let res = await axios.get(`/api/usertracking/geo_address/${nrmLat}/${nrmLng}`);
    return res;
}

/** Get an address from a lat/lng */
async function getGeoLat(address = '') {
    let res = await axios.get(`/api/usertracking/geo_lat/${address}`);
    return res;

}

async function createTracking(
    Id,
    entityId = '00000000-0000-0000-0000-000000000000',
    entityType = '',
    userIdOverride = '00000000-0000-0000-0000-000000000000',
) {
    let gpsPos = await getGPSCoordinates();
    // allow to override a userID, because activityAssignment Status can be set by a manager,
    // BUT the activityAssignment belongs to the ASSIGNED USER
    // ToDo: CHECK IF OK WITH THIS
    // don't log no GPS positions
    if (!gpsPos.error) {
        let battery = await getDeviceBattery();
        let user = null;
        let userId = null;
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (userIdOverride != '00000000-0000-0000-0000-000000000000') {
            userId = userIdOverride;
        } else {
            userId = user.Id || '00000000-0000-0000-0000-000000000000';
        }

        Id = helper.checkId(Id) ? Id : uuidv4();

        return await createUserPosition(
            Id,
            userId,
            gpsPos.latitude,
            gpsPos.longitude,
            entityId,
            entityType,
            battery,
            gpsPos.accuracy,

        );
    } else {
        console.log('ERROR GPS', gpsPos)
        return {
            success: false,
            error: gpsPos.error,
        };
    }
}

async function createUserPosition(
    Id,
    userId,
    latitude,
    longitude,
    entityId = '00000000-0000-0000-0000-000000000000',
    entityType = '',
    battery = '',
    accuracy = null,
) {
    let data = {
        id: Id,
        userId: userId,
        latitude: latitude,
        longitude: longitude,
        accuracy: accuracy,
        entityId: entityId,
        deviceTime: DateTime.now().toISO(),
        serverTime: DateTime.now().toISO(),
        entityType: entityType,
        battery: battery,
    };

    let user = null;
    let globalUserId = '00000000-0000-0000-0000-000000000000';

    if (
        (latitude != 0 && longitude != 0) ||
        (latitude != 999 && longitude != 999)
    ) {
        if (navigator.onLine) {
            let res = await axios.post(`/api/usertracking`, data);
            return res.data;
        } else {
            if (entityType == 'user') {
                if (!db.userDB || _.isEmpty(db.userDB)) {
                    db.refreshUserDb();
                }
                //SAVE OFFLINE POSITION RECORDS
                await db.userDB.put({
                    _id: data.id,
                    from: 'offline',
                    userPosition: data,
                });

                if (localStorage.getItem('user')) {
                    user = JSON.parse(localStorage.getItem('user'));
                    globalUserId =
                        user.Id || '00000000-0000-0000-0000-000000000000';
                }

                let change_id = uuidv4();
                await db.globalsDB.put({
                    _id: change_id,
                    change: {
                        version: version,
                        entityId: data.id,
                        userId: globalUserId, // was: data.userId, but GLOBALS should be the userID of the logged in user. But the position record must have the userID of the activity
                        entityType: 'userPosition',
                        dateModified: DateTime.now().toISO(),
                        changeType: 'INSERT',
                    },
                });
                return {
                    success: true,
                    data: {
                        userPosition: data,
                    },
                };
            } else {
                return {
                    success: true,
                    data: {
                        userPosition: data,
                    },
                };
            }
        }
    } else {
        return {
            success: false,
            error: {
                code: 101,
                message: 'No position available',
            },
        };
    }
}
async function getActivityLogData(userId, startDate, endDate) {
    try {
        startDate = DateTime.fromJSDate(new Date(startDate)).toISO();
        endDate = DateTime.fromJSDate(new Date(endDate)).toISO();

        let res = await axios.get(
            `/api/usertracking/datewithid/${startDate}/${endDate}/${userId}`,
        );
        if (res && res.data.result.success && res.data.data) {
            return res.data;
        } else {
            //and API error has occurred, do whatever....
            return res;
        }
    } catch (error) {
        return error;
    }
}

async function getDeviceBattery() {
    if (navigator.getBattery) {
        try {
            let battery = await navigator.getBattery();
            return battery.level;
        } catch (e) {
            return 0;
        }
    } else {
        return 0;
    }
}


let getLocation = () => {
    return new Promise(function (resolve, reject) {
        // Promisifying the geolocation API
        navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
        );
    });
};

async function getGPSCoordinates() {
    return new Promise((resolve) => {
        console.log('GEO', navigator.geolocation)
        if (navigator.geolocation) {
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            getLocation().then((position) => {
                let cachedPosition = { "latitude": helper.normalizeCoordinate(position.coords.latitude), "longitude": helper.normalizeCoordinate(position.coords.longitude), "accuracy": position.coords.accuracy, "update": DateTime.now().toISO() };
                localStorage.setItem('lastGpsPosition', JSON.stringify(cachedPosition));
                return resolve(cachedPosition);
            }).catch((error) => {
                let returnError = { error: 'Unknown' }
                //console.log(error)
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        // console.error("User denied the request for Geolocation.");
                        returnError = { "error": "User denied the request for Geolocation.", "latitude": 999, "longitude": 999, "accuracy": null };
                        break;

                    case error.POSITION_UNAVAILABLE:
                        // console.error("Location information is unavailable.");
                        returnError = { "error": "Location information is unavailable.", "latitude": 999, "longitude": 999, "accuracy": null };
                        break;

                    case error.TIMEOUT:
                        // console.error("The request to get user location timed out.");
                        returnError = { "error": "The request to get user location timed out.", "latitude": 999, "longitude": 999, "accuracy": null };
                        break;

                    case error.UNKNOWN_ERROR:
                        // console.error("An unknown error occurred.");
                        returnError = { "error": "An unknown error occurred.", "latitude": 999, "longitude": 999, "accuracy": null };
                        break;
                }
                //check if we can use the cached position
                let cachedPosition = JSON.parse(localStorage.getItem('lastGpsPosition') || '{}');
                if (cachedPosition && cachedPosition.latitude && cachedPosition.longitude && cachedPosition.accuracy && cachedPosition.update) {

                    let lastUpdate = DateTime.fromISO(cachedPosition.update);
                    if (lastUpdate > DateTime.now().minus({ hours: 1 })) {
                        return resolve(cachedPosition);
                    } else if (lastUpdate > DateTime.now().minus({ hours: 6 })) {
                        cachedPosition.accuracy = 9999;
                        return resolve(cachedPosition);
                    }
                }

                return resolve(returnError);
            })

        } else {
            return reject({ "error": "no geolocation available for device", "latitude": 999, "longitude": 999, "accuracy": null });
        }
    });
}

async function getLoginHistoryCount(start, end) {

    if (navigator.onLine) {
        const res = await axios(`/api/users/dashboard/login-history?start=${start}&end=${end}`);
        return res.data;
    }
    return null;

}
