export const state = {
    type: '',
    value: ''
};

export const getters = {
    getSearchType: state => {
        return state.type;
    },
    getSearchValue: state => {
        return state.value;
    },
};

export const actions = {
    async setType({ commit }, value) {
        commit('SET_TYPE', value);
    },
    async setValue({ commit }, value) {
        commit('SET_VALUE', value);
    }
};

export const mutations = {
    SET_TYPE: (state, value) => {
        state.type = value;
    },
    SET_VALUE: (state, value) => {
        console.debug("HERE");
        state.value = value;
    },
};
