import PouchDB from 'pouchdb';
import _ from 'lodash';
let databaseId;
var userDB, globalsDB, authDB;
globalsDB = new PouchDB(process.env.VUE_APP_USERDB_PREFIX + '_globals');
authDB = new PouchDB(process.env.VUE_APP_USERDB_PREFIX + '_auth');
async function refreshUserDb() {
    const user = JSON.parse(localStorage.getItem('user')) || { Id: '' };
    //console.log('***** POUCHDB Init: begin setup for userDB', user.Id)
    if (user && user.Id != '') {
        databaseId = user.Id || '';
        const databaseName = `user_${process.env.VUE_APP_USERDB_PREFIX}_${databaseId}`;
        if (
            databaseId !== '' &&
            process.env.VUE_APP_COUCHDB_URL &&
            process.env.VUE_APP_COUCHDB_USERNAME &&
            process.env.VUE_APP_COUCHDB_PASSWORD
        ) {

            userDB = new PouchDB(databaseName);
            return userDB;
        }
    }
    return undefined;
}
export default { userDB, globalsDB, authDB, refreshUserDb };
