import { geoAssetAlertService } from '../../services/geo.assetAlert.service';

export const state = {
    allAlerts: [],
    actions: [],
    events: [],
};

export const getters = {
    getActions: state => {
        return state.actions;
    },
    getEvents: state => {
        return state.events;
    },
};

export const actions = {
    async fetchActions({ commit }) {
        const {
            data: { actions },
        } = await geoAssetAlertService.getAlertActions();
        commit('setActions', actions);
        return actions;
    },
    async fetchevents({ commit }) {
        const {
            data: { events },
        } = await geoAssetAlertService.getAlertEvents();
        commit('setEvents', events);
        return events;
    },
    async createAlert({ commit }, newAlertObj) {
        const alert = await geoAssetAlertService.createAlert(newAlertObj);
        commit('addAlert', [alert]);
    },
    async updateAlert({ commit }, alertObj) {
        const alert = await geoAssetAlertService.updateAlert(alertObj);
        commit('addAlert', [alert]);
    },
    async fetchAlerts({ commit }, items) {
        const { assetId, itemsPerPage, page, sortField, sortOrder, searchQuery } = items;
        const { data } = await geoAssetAlertService.getAssetsAlerts(assetId, page, itemsPerPage, sortField, sortOrder, searchQuery);
        commit('setAlerts', data);
        return data;
    },
};

export const mutations = {
    setActions: (state, actions) => {
        state.actions = actions;
    },
    setEvents: (state, events) => {
        state.events = events;
    },
    setAlerts: (state, alerts) => {
        state.alerts = alerts;
    },
    addAlert(state, alerts) {
        alerts.forEach(alert => {
            state.allAlerts.push(alert);
        });
    },
};
