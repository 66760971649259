import { reactive } from 'vue'
import { ref } from 'vue';

const type = ref('');
const value = ref('');

export const appSearch = reactive({
    setType(typeValue) {
        type.value = typeValue;
    },
    setValue(searchValue) {
        value.value = searchValue;
    },

    getType(){
        return type.value;
    },
    getValue() {
        return value.value;
    }
})