import * as xlsx from 'xlsx/xlsx';
import Toastify from 'toastify-js';
import { roleService } from '@/services/role.service';
// import { _ } from 'core-js';
import _ from 'lodash';
import { userActivityLoggerService } from '@/services/userActivityLogger.service';
import { myStore } from '@/components/topbarUtil';
export default {
    data() {
        return {
            // refresh variables
            user: { tours: [] },
            userId: '',
            userTenantId: null,
            locationAvailable: false,
            locationRequired: false,
            language: [],
        };
    },
    created() {
        if (localStorage.getItem('user') != null) {
            this.user = JSON.parse(localStorage.getItem('user') || {});
            this.userId = this.user.Id;
            // make sure there is a default tenant ID;
        }
        //   console.log('user', this.user);
    },
    mounted() {
        this.userTenantId = this.getDefaultTenantId();
        this.getSelectedTenantId();
        this.setLanguage();
    },
    computed: {
        currentUser() {
            return JSON.parse(localStorage.getItem('user') || {});
        },
    },
    methods: {
        applyTranslation(label) {
            //translate labels from user / tenant defined language
            if (!this.language) {
                this.language = JSON.parse(localStorage.getItem('language')) || [];
            }
            if (this.language.length > 0) {
                //if the input label contains any of the language values, then replace all occurrances of the matching word or phrase using a case sensitive regular expression in the label with the language value
                let translation = this.language.find(x => label.includes(x.label));
                if (translation) {
                    return label.replace(new RegExp(translation.label, 'g'), translation.value);
                } else {
                    return label;
                }
            } else {
                return label;
            }
        },
        generateSpreadsheet(data, sheetName = 'report') {
            // const REPORTS_DIR = process.env.REPORTS_DIR || 'public/reports';
            const filename = `${sheetName}_${new Date().getTime()}.xlsx`;
            const filePath = `${filename}`;

            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();

            xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);

            xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'buffer',
            });

            workbook.Props.Title = sheetName;

            xlsx.writeFile(workbook, filePath);

            let heading = 'Report';
            heading = sheetName + ' generated successfully';

            Toastify({
                text: heading,
                className: 'info',
                style: {
                    background: 'linear-gradient(to right, #00b09b, #96c93d)',
                },
            }).showToast();
            return;
        },
        async refreshUser() {
            if (localStorage.getItem('user') != null) {
                this.user = JSON.parse(localStorage.getItem('user') || {});
                this.userId = this.user.Id;
                // make sure there is a default tenant ID;
            }
            return;
        },
        getUserEmail() {
            return this.user.emailAddress || '';
        },
        isInRole(roleName) {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === roleName.toLowerCase())) {
                    return true;
                }
            }
            return false;
        },
        async isSystemRole(roleId) {
            let { data } = await roleService.getRoleById(roleId);

            if (data.role && data.role.systemRole == true) {
                return true;
            }
            return false;
        },
        hasPermission(permissionHierarchy) {
            // global admin has all permissions
            if (!_.isEmpty(this.user) && this.isGlobalAdministrator()) {
                return true;
            }

            if (!_.isEmpty(this.user)) {
                if (this.user.rolepermissions.findIndex(x => x.hierarchy == permissionHierarchy) > -1) {
                    return true;
                }
            }
            return false;
        },
        isAdmin() {
            if (!_.isEmpty(this.user)) {
                if (
                    this.user.roles?.find(
                        role =>
                            role.name.toLowerCase() === 'global administrator' ||
                            role.name.toLowerCase() === 'administrator' ||
                            role.name.toLowerCase() === 'company administrator' ||
                            role.name.toLowerCase() === 'company admin' ||
                            role.name.toLowerCase() === 'admin',
                    )
                ) {
                    return true;
                }
            }
            return false;
        },
        isTenantAdmin() {
            if (!_.isEmpty(this.user)) {
                if (
                    this.user.roles?.find(
                        role =>
                            role.name.toLowerCase() === 'company administrator' ||
                            role.name.toLowerCase() === 'administrator' ||
                            role.name.toLowerCase() === 'admin',
                    )
                ) {
                    return true;
                }
            }
            return false;
        },
        isLoanOfficer() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'loan officer')) {
                    return true;
                }
            }
            return false;
        },
        isCreditOfficer() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'credit officer')) {
                    return true;
                }
            }
            return false;
        },
        isTeamLeader() {
            return false;
            // if (!_.isEmpty(this.user)) {
            //     if (this.user.roles?.find(role => role.name.toLowerCase() === 'team leader')) {
            //         return true;
            //     }
            // }
            // return false;
        },
        isSalesManager() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'sales manager')) {
                    return true;
                }
            }
            return false;
        },
        isBranchManager() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'branch manager')) {
                    return true;
                }
            }
            return false;
        },
        isZonalManager() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'zonal manager')) {
                    return true;
                }
            }
            return false;
        },
        isProductManager() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'product manager')) {
                    return true;
                }
            }
            return false;
        },
        isRegionalManager() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'regional manager')) {
                    return true;
                }
            }
            return false;
        },
        isManagingDirector() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'managing director')) {
                    return true;
                }
            }
            return false;
        },
        isManager() {
            if (!_.isEmpty(this.user)) {
                if (
                    this.user.roles?.find(
                        role =>
                            role.name.toLowerCase() === 'managing director' ||
                            role.name.toLowerCase() === 'regional manager' ||
                            role.name.toLowerCase() === 'branch manager' ||
                            role.name.toLowerCase() === 'zonal manager' ||
                            role.name.toLowerCase() === 'sales manager' ||
                            role.name.toLowerCase() === 'company administrator' ||
                            role.name.toLowerCase() === 'product manager',
                    )
                ) {
                    return true;
                }
            }
            return false;
        },
        isGlobalAdministrator() {
            if (!_.isEmpty(this.user)) {
                if (this.user.roles?.find(role => role.name.toLowerCase() === 'global administrator')) {
                    return true;
                }
            }
            return false;
        },
        async setUserTenants(tenants) {
            this.user.tenants = tenants;
            await this.saveUser();
            return;
        },
        async setSelectedTenantId(tenantId) {
            this.user.selectedTenant = tenantId;
            window.newTenantId = tenantId;
            this.$store.commit('dashboard/setTenant', tenantId);
            myStore.localTenant = tenantId; 
            await this.saveUser();
            return;
        },
        async getSelectedTenantId() {
            if (!this.user.selectedTenant) {                
                await this.setSelectedTenantId(this.user?.tenants[0]?.Id);
            }
            return this.user.selectedTenant;
        },
        getTenantName(id) {
            let tenant = {};

            if (!_.isNull(id) && this.user.tenants) {
                tenant = this.user.tenants.find(x => x.Id === id);
            }
            if (!_.isEmpty(tenant)) {
                return tenant.name;
            } else {
                return this.getSelectedTenantDetail(this.getDefaultTenantId());
            }
        },
        async getSelectedTenantDetail() {
            let tenant = {};
            // if (!this._numExecGetSelectedTenantDetail) {
            //     this._numExecGetSelectedTenantDetail = 0;
            // }
            // this._numExecGetSelectedTenantDetail++;
            // if (this._numExecGetSelectedTenantDetail > 10) {
            //     return null;
            // }
            // if (this._numExecGetSelectedTenantDetail ==2) {
            //     //HACK: this is a hack to fix the issue of the user not having a tenant
            //     localStorage.setItem('tenant', this.user?.tenants[0]?.Id);
            // }
            if (localStorage.getItem('tenant')) {
                this.user.selectedTenant = localStorage.getItem('tenant');
                //check if user has this tenant
                let indx = this.user.tenants.findIndex(x => x.Id === this.user.selectedTenant);
                if (indx===-1 && this.user?.tenants[0]?.Id) {                    
                    localStorage.setItem('tenant', this.user?.tenants[0]?.Id);
                    this.user.selectedTenant = this.user?.tenants[0]?.Id;
                }

            }

            if (!_.isNull(this.user.selectedTenant)) {
                tenant = this.user.tenants.find(x => x.Id === this.user.selectedTenant);
            }
            if (!_.isEmpty(tenant)) {
                return tenant;
            } else {
                await new Promise(resolve => setTimeout(resolve, 3000));
                let tenant = await this.getSelectedTenantDetail(await this.getSelectedTenantId());
                return tenant;
            }
        },
        getDefaultTenantId() {
            return this.user.tenants[0]?.Id;
        },
        async saveUser() {
            localStorage.setItem('user', JSON.stringify(this.user));
            return;
        },
        async checkLocationRequired() {
            let currentTenant = await this.getSelectedTenantDetail();
            this.locationRequired = currentTenant.locationRequired;
            return this.locationRequired;
        },
        async checkLocationAvailability() {
            let gps = await userActivityLoggerService.getGPSCoordinates();

            if (gps.error) {
                this.locationAvailable = false;
                if (this.locationRequired) {
                    Toastify({
                        text: 'Enable location access in-order to update activity status and start or stop your day!',
                        className: 'info',
                        style: {
                            background: 'linear-gradient(to right, #00b09b, #96c93d)',
                        },
                    }).showToast();
                }
            } else {
                this.locationAvailable = true;
            }
            return this.locationAvailable;
        },
        async getLocation() {
            let address = null;
            if (navigator.onLine) {
                let gps = await userActivityLoggerService.getGPSCoordinates();
                let results = await userActivityLoggerService.getGeoAddress(gps.latitude, gps.longitude);

                address = results.data.data;

                if (gps.error) {
                    this.locationAvailable = false;
                    if (this.locationRequired) {
                        Toastify({
                            text: 'Enable location access in-order to update activity status and start or stop your day!',
                            className: 'info',
                            style: {
                                background: 'linear-gradient(to right, #00b09b, #96c93d)',
                            },
                        }).showToast();
                    }
                } else {
                    localStorage.setItem('lastGpsPosition', JSON.stringify(gps));
                    this.locationAvailable = true;
                }
            }
            return address;
        },
        async setLanguage() {
            this.language = JSON.parse(localStorage.getItem('language') || '[]');
        },
    },
};
