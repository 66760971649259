import moment from 'moment';
import { DateTime } from 'luxon';
import _ from 'lodash';

function normalizeCoordinate(input) {
    return (parseInt(input * 10000) / 10000);
}


// Remove Quotes & Square Brackets
const formatArray = value => {
    return _.replace(_.toString(value), new RegExp('"[]', 'g'), '') || '(None)';
};

// get an array list of years based on given start year until current year.
const generateYears = startYear => {
    const currentYear = new Date().getFullYear();
    const years = [];
    let year;
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
        year = startYear++;
        years.push(year);
    }
    return years;
};

// get an array list of years from current year to next subsequent number of years.
const generateFutureYears = total => {
    let year = new Date().getFullYear();
    const years = [year];
    for (let i = 1; i < total; i++) {
        year++;
        years.push(year);
    }
    return years;
};

const getMonths = () => {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
};

// get an dropdown items for tenant type

const optionTenantTypes = [
    {
        id: 1,
        type: 'Group',
    },
    {
        id: 2,
        type: 'Company',
    },
    {
        id: 3,
        type: 'Branch',
    },
    {
        id: 4,
        type: 'Team',
    },
];

// convert dataURL string (base64) to File object
const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

const strToObj = str => {
    let obj = {};
    if (str && typeof str === 'string') {
        let objStr = str.match(/\{(.)+\}/g);
        eval(`obj =${objStr}`);
    }
    return obj;
};

const isAlphaNumericSpace = ch => {
    return ch.match(/^[a-z0-9]+[a-z0-9\s]+[a-z0-9]+$/i) !== null;
};


const isAlphaNumeric = ch => {
    return ch.match(/^[a-z0-9]+$/i) !== null;
};
const isFname = ch =>{
    return ch.match(/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,20}$/i) !== null;
};
const isAlpha = ch => {
    return ch.match(/^[a-z]+ ?([a-z]+$){1}/i) !== null;
};

const isIDnumber = n => {
    return n.match(/^\d{13,}$/i) !== null;
};
const isTelephoneNumber = n => {
    return n.match(/^[(00)|(+)][0-9]{11,12}$/gm) !== null;
};
const isTokenExpired = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''),
    );

    const { exp } = JSON.parse(jsonPayload);
    return Date.now() >= exp * 1000;
};

const wait = timeout => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
};

const templateTypes = () => {
    return [
        {
            key: 1,
            value: 'HTML',
        },
        {
            key: 2,
            value: 'Word',
        },
        {
            key: 3,
            value: 'Text',
        },
        {
            key: 4,
            value: 'WhatsApp',
        },
    ];
};

const onTableSelectAll = (tableRef, data) => {
    if (!tableRef) {
        return;
    }

    if (tableRef.allSelected) {
        data.forEach(item => {
            item.vgtSelected = true;
        });
    } else {
        data.forEach(item => {
            item.vgtSelected = false;
        });
    }
};
const onTableUnSelectAll = (tableRef, data) => {
    if (!tableRef) {
        return;
    }
        data.forEach(item => {
            item.vgtSelected = false;
        });

};
const formatDateTime = (inDate, format) => {
    if (!format) {
        format = '';
    }
    if (format === '') {
        format = 'YYYYMMDD';
    }
    if (inDate) {
        return moment(String(inDate)).format(format);
    }
    return inDate;
};
const formatDateTimeISO = (inDate, format = 'yyyy-MM-DD HH:mm') => {
    if (!format) {
        format = '';
    }
    if (format === '') {
        format = 'yyyy-MM-DD HH:mm';
    }
    if (inDate && moment(inDate).isValid()) {
        return moment(String(inDate)).format(format);
    } else {
        return inDate;
    }
};

const formateTimePast = inDate => {
    if (inDate) {
        return timeAgo(inDate);
    }
};
const bankersRound = (n, d = 2) => {
    var x = n * Math.pow(10, d);
    var r = Math.round(x);
    var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
    return br / Math.pow(10, d);
}
const formatTimePastMoment = date => {
    if(moment(String(date)).isValid()){
        return moment.duration(moment().diff(moment(String(date)))).humanize()
    }
    return "";
}
const timeAgo = date => {
    const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

    let dateTime = DateTime.fromJSDate(date);
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find(unit => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en-GB', {
        numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

const formatDecimal = value => {
    return new Intl.NumberFormat('en-US').format(value);
};
const formatLatLong = value => {
    return  Number( value).toFixed(6);
};

const formatAgentDate = date => {
    if (date.includes('T')) {
        date = date.replace('T', ' ');
        date = date.substring(0, date.indexOf('.'));
    }
    date = date.substring(0, date.indexOf(':') + 3);
    return date;
};

const price = (num, currency = 'R') => {
    return `${currency} ${Number(num).toFixed(2)}`;
};

const checkId = Id => {
    if (Id && Id != null) {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        let result = regexExp.test(Id);
        return result;
    }
    return false;
};

const getUserId = () => {
    return JSON.parse(localStorage.getItem('user')).Id;
};

const stringToArray = str => {
    const result = [];
    if (_.isEmpty(str)) {
        return result;
    }
    let arrItems = str.split(',');
    arrItems.forEach(item => {
        let arrItem = item.split(':');
        result.push({
            id: arrItem[0],
            name: arrItem[1],
        });
    });
    return result;
};

const PMT = (rate, nperiod, pv, fv = 0, type = 0) => {
    if (rate == 0) return -(pv + fv) / nperiod;
    var pvif = Math.pow(1 + rate, nperiod);
    var pmt = rate / (pvif - 1) * -(pv * pvif + fv);
    if (type == 1) {
        pmt /= (1 + rate);
    };
    return pmt;
};

const PV = (rate, nper, pmt, fv) => {
    rate = parseFloat(rate);
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    fv = parseFloat(fv);
    let pv_value = 0;
    if (rate == 0) { // Interest rate is 0
        pv_value = -(fv + (pmt * nper));
    } else {
        let x = Math.pow(1 + rate, -nper);
        let y = Math.pow(1 + rate, nper);
        pv_value = - (x * (fv * rate - pmt + y * pmt)) / rate;
    }
    pv_value = conv_number(pv_value, 2);
    return (pv_value);
};

const conv_number = (expr, decplaces) => {
    var str = "" + Math.round(eval(expr) * Math.pow(10, decplaces));
    while (str.length <= decplaces) {
        str = "0" + str;
    }

    var decpoint = str.length - decplaces;
    return (str.substring(0, decpoint) + "." + str.substring(decpoint, str.length));
};

const stringToArrayObjectNames = str => {
    const result = [];
    if (_.isEmpty(str)) {
        return result;
    }
    let arrItems = str.split(',');
    arrItems.forEach(item => {
        let arrItem = item.split(':');
        result.push({            
            name: arrItem[1],
        });
    });
    return result;
};

const stringIdNamesToNames = str => {
    let result = '';
    if (_.isEmpty(str)) {
        return result;
    }
    let arrItems = str.split(',');
    arrItems.forEach(item => {
        let arrItem = item.split(':');
        result = result + arrItem[1] + ', ';
        });
    if (result.length>0)
    {
     result = result.substring(0, result.length-2);
    }

    return result;
};

const getWidget = (name) => {
    const widgetName = name.toLowerCase();
    return `../components/widgets/${widgetName}.vue`;
}

export default {
    normalizeCoordinate,
    formatArray,
    generateYears,
    dataURLtoFile,
    strToObj,
    isAlphaNumeric,
    isAlpha,
    isFname,
    isTokenExpired,
    optionTenantTypes,
    bankersRound,
    wait,
    templateTypes,
    onTableSelectAll,
    onTableUnSelectAll,
    formatDateTime,
    formatDateTimeISO,
    formateTimePast,
    formatDecimal,
    formatAgentDate,
    formatLatLong,
    formatTimePastMoment,
    generateFutureYears,
    getMonths,
    price,
    checkId,
    getUserId,
    stringToArray,
    PV,
    PMT,
    isIDnumber,
    isTelephoneNumber,
    stringToArrayObjectNames,
    stringIdNamesToNames,
    isAlphaNumericSpace,
    getWidget,
    
};
