import { ref,reactive, computed } from 'vue';
import { useStore } from 'vuex'; 
export function useUserAuth() {
    const store = useStore();
    const currentUser = computed(() => JSON.parse(localStorage.getItem('user')) || []);
    const selectedTenant = computed(() => store.state.dashboard.tenant);

    const hasPermission = (permissionHierarchy) => {
        if(isGlobalAdministrator()) {
            return true;
        }
         
        if (currentUser.value.rolepermissions?.findIndex(x => x.hierarchy == permissionHierarchy) > -1) {
            return true;
        }
        return false;
    }
    const isGlobalAdministrator = () => {
        if(currentUser.value.roles?.find(role => role.name.toLowerCase() === 'global administrator')) {
            return true;
        }
    }
    return {
        currentUser,
        hasPermission,
        isGlobalAdministrator,
        selectedTenant,
    };
}
