export const state = {
    layoutType: 'vertical',
    layoutWidth: 'fluid',
    leftSidebarType: 'light',
    topbar: 'dark',
    loader: false,
    isWidgetSidebarOpen: false,
};

export const getters = {};

export const mutations = {
    CHANGE_LAYOUT(state, layoutType) {
        state.layoutType = layoutType;
    },
    CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
        state.layoutWidth = layoutWidth;
    },
    CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
        state.leftSidebarType = leftSidebarType;
    },
    CHANGE_TOPBAR(state, topbar) {
        state.topbar = topbar;
    },
    LOADER(state, loader) {
        state.loader = loader;
    },
    TOGGLE_WIDGET_SIDEBAR(state, value) {
        state.isWidgetSidebarOpen = value;
    },
    TOGGLE_APP_SEARCH(state, value) {
        state.isAppSearchAvailable = value;
    },
};

export const actions = {
    changeLayoutType({ commit }, { layoutType }) {
        commit('CHANGE_LAYOUT', layoutType);
    },

    changeLayoutWidth({ commit }, { layoutWidth }) {
        commit('CHANGE_LAYOUT_WIDTH', layoutWidth);
    },

    changeLeftSidebarType({ commit }, { leftSidebarType }) {
        commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType);
    },

    changeTopbar({ commit }, { topbar }) {
        commit('CHANGE_TOPBAR', topbar);
    },

    changeLoaderValue({ commit }, { loader }) {
        commit('LOADER', loader);
    },

    toggleWidgetSidebar({ commit }, value) {
        commit('TOGGLE_WIDGET_SIDEBAR', value);
    },

    toggleAppSearch({ commit }, value) {
        commit('TOGGLE_APP_SEARCH', value);
    },
};
