<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link to="/" class="logo">
                        <span class="logo-sm" v-if="themeName === 'phl'">
                            <img class="img-sm" src="@/assets/images/phl_logo.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'platinum'">
                            <img class="img-sm" src="@/assets/images/subs-logos/platinum-kenya-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'momentum'">
                            <img class="img-sm" src="@/assets/images/subs-logos/moment-credit-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'ez'">
                            <img class="img-sm" src="@/assets/images/subs-logos/ez-track-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'eezytrack'">
                            <img class="img-sm" src="@/assets/images/subs-logos/ez-track-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'tracknav'">
                            <img class="img-sm" src="@/assets/images/subs-logos/ez-track-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'premier'">
                            <img class="img-sm" src="@/assets/images/subs-logos/premier-credit-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'premiercredit'">
                            <img class="img-sm" src="@/assets/images/subs-logos/premier-credit-logo-edit.png" alt height="32" />
                        </span>
                        <span class="logo-sm" v-if="themeName === 'viva'">
                            <img class="img-sm" src="@/assets/images/subs-logos/viva365-logo-edit.png" alt height="32" />
                        </span>
                    </router-link>
                </div>
                <button
                    @click="toggleMenu"
                    type="button"
                    class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
                    id="vertical-menu-btn"
                    aria-label="Toggle Sidebar"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>

                <!-- App Search-->
                 <form class="app-search d-none d-lg-block">
                    <div class="position-relative" v-if="isAppSearchAvailable">
                        <div class="input-group">
                            <input
                            id="searchBar"
                            type="text"
                            class="form-control"
                            :placeholder="$t('navbar.search.text')"
                            v-model="appSearchValue"
                            @keydown.enter.prevent="appSearchChange"
                            @focus="appSearchChange"
                        />
                        <div class="input-group-append">
                            <button 
                             class="btn btn-primary" 
                             @click.prevent="appSearchChange"
                             aria-label="search button"
                              >
                            <i class="mdi mdi-magnify"></i>
                            </button>
                        </div>
                        </div>

                    </div>
                </form> 

             
            </div>

            <div class="d-flex align-items-center">
                <b-dropdown
                    variant="white"
                    class="d-inline-block d-lg-none ms-2 btn-search"
                    toggle-class="header-item noti-icon"
                    right
                    menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                >
                    <!-- <template v-slot:button-content>
                        <i class="uil-search"></i>
                    </template> -->
                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="top-search"
                                    :placeholder="$t('navbar.search.text')"
                                    aria-label="Recipient's username"
                                />
                                <div class="input-group-append d-flex">
                                    <button class="btn btn-primary" type="submit">
                                        <i class="mdi mdi-magnify"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </b-dropdown>

                <b-dropdown
                    class="select-tenant"
                    variant="white"
                    :text="this.getTenantName(myStore.localTenant)"
                    toggle-class="header-item"
                    auto-close="outside"
                    v-if="!this.isLoanOfficer() && !this.isTeamLeader()"
                >
                    <b-dropdown-item>
                        <div class="reset-tenants" v-if="showResetTenant">
                            <button class="btn btn-sm btn-primary" @click="onResetTenants">Reset</button>
                        </div>
                        <Tree
                            :value="tenantsNodes"
                            :expandedKeys="expandedKeys"
                            v-model:selectionKeys="selectedKey"
                            selectionMode="single"
                            :filter="true"
                            @nodeSelect="onNodeSelected"
                        >
                        </Tree>
                    </b-dropdown-item>
                </b-dropdown>
                <span v-else>
                    {{ this.getTenantName(this.initialTenant) }}
                </span>

                <!-- <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :link-class="{ active: entry.language === current_language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown> -->

                <!-- <b-dropdown
          variant="white"
          class="d-none d-lg-inline-block ms-1"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-apps"></i>
          </template>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/github.png" alt="Github" />
                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.bitbucket") }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown> -->

                <div class="dropdown d-lg-inline-block ms-1" v-if="agentStatus">
                    <span class="text-success">
                        <router-link class="dropdown-item green" to="/agents/landingpage">
                            <i class="uil uil-clock font-size-18 align-middle me-1"></i>
                        </router-link>
                    </span>
                </div>
                <div class="dropdown d-lg-inline-block ms-1" v-else>
                    <span class="text-danger">
                        <router-link class="dropdown-item red" to="/agents/landingpage" aria-label="Agent Status indicating offline">
                            <i class="uil uil-clock font-size-18 align-middle me-1"></i>
                        </router-link>
                    </span>
                </div>
                <v-offline ping="https://www.google.com" @detected-condition="handleConnectivityChange">
                    <div class="dropdown d-none d-lg-inline-block ms-1" v-if="onLine">
                        <button type="button" title="Application is currently online." class="btn header-item noti-icon waves-effect green">
                            <i class="uil-wifi"></i>
                        </button>
                    </div>
                    <div class="dropdown d-none d-lg-inline-block ms-1" v-else>
                        <button type="button" title="Application is currently offline." class="btn header-item noti-icon waves-effect red">
                            <i class="uil-wifi"></i>
                        </button>
                    </div>
                </v-offline>

                <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
                    <router-link class="dropdown-item" to="/clients/phone-book">
                        <i class="uil uil-phone font-size-18 align-middle me-1 text-muted"></i>
                    </router-link>
                </div> -->

                <div class="dropdown d-none d-lg-inline-block ms-1">
                    <button 
                    v-if="isFullScreen"
                    type="button" 
                    class="btn header-item noti-icon waves-effect" 
                    data-toggle="fullscreen" 
                    aria-label="Fullscreen Toggle fullscreen"
                    @click="initFullScreen"
                    >
                        <i class="uil-compress"></i>
                    </button>

                    <button 
                    v-else
                    type="button" 
                    class="btn header-item noti-icon waves-effect" 
                    data-toggle="small-screen" 
                    aria-label="Fullscreen Toggle small screen"
                    @click="initFullScreen"
                    >
                        <i class="uil-minus-path"></i>
                    </button>
                </div>
                <notification></notification>
                <div v-if="this.$route.path === '/'">
                    <i
                        @click="toggleWidgetSidebar(!isWidgetSidebarOpen)"
                        class="pi pi-align-right close-widgets-sidebar"
                        title="Dashboard Widgets"
                    ></i>
                </div>
                <b-dropdown class="d-inline-block" toggle-class="header-item" right variant="white" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <vue-gravatar :email="userEmail" :size="30" class="rounded-circle header-profile-user" aria-label="current user's image"/>
                        <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{ userName }}</span>
                        <!-- <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i> -->
                    </template>

                    <!-- item-->
                    <router-link class="dropdown-item" to="/profile">
                        <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                        <span class="align-middle">{{ $t('navbar.dropdown.user.list.profile') }}</span>
                    </router-link>
                    <router-link class="dropdown-item" to="/profile/change-password">
                        <i class="uil uil-lock font-size-18 align-middle text-muted me-1"></i>
                        <span class="align-middle">{{ $t('navbar.dropdown.user.list.changepassword') }}</span>
                    </router-link>
                    <!-- <a class="dropdown-item d-block" href="#">
            <i
              class="uil uil-cog font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.user.list.settings")
            }}</span>
            <span class="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
          </a> -->
                    <router-link class="dropdown-item" data-testid="logout" to="/logout">
                        <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
                        <span class="align-middle">{{ $t('navbar.dropdown.user.list.logout') }}</span>
                    </router-link>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>

<style lang="scss"></style>

<script>
import notification from './notification/index.vue';
import { VOffline } from 'v-offline';
import { tenantService } from '@/services/tenant.service';
import { useStore } from 'vuex';
import { myStore } from './topbarUtil';
import { useRouter } from 'vue-router';
import user from '@/mixins/user';
import _ from 'lodash';
import { agentService } from '@/services/agent.service';
import { notificationService } from '@/services/notification.service';
import { useSocketIO } from '@/use/useSocketIO';
import { mapState, mapActions } from 'vuex';
import { onBeforeMount } from 'vue';
import { appSearch } from '@/composables/appSearch';

export default {
    components: {
        notification,
        VOffline,
    },
    data() {
        return {
            languages: [
                {
                    flag: require('@/assets/images/flags/us.jpg'),
                    lang: 'en',
                    title: 'English',
                },
            ],

            current_language: 'en',
            isFullScreen: false,    
            text: null,
            flag: null,
            value: null,
            userName: '',
            myStore,
            userEmail: 'default.user@platcorpgroup.com',
            onLine: true, // properties for dealing with online status
            onlineSlot: 'online',
            offlineSlot: 'offline',
            tenants: [],
            store: {},
            tenantQuery: '',
            initialTenant: '',
            tenantsNodes: [],
            expandedKeys: {},
            selectedKey: {},
            showResetTenant: false,
            appSearchValue: '',
        };
    },
    mixins: [user],
    computed: {
        themeName() {
            return this.$themeName();
        },
        ...mapState({
            agentStatus: state => state.offline.agent,
            isWidgetSidebarOpen: state => state.layout.isWidgetSidebarOpen,
            isAppSearchAvailable: state => state.layout.isAppSearchAvailable,
        }),
    },
    async beforeMount() {
        if (!this.myStore.localTenant && this.user && this.user.tenants && this.user.tenants.length > 0) {
            this.myStore.localTenant = this.user.selectedTenant;
        }
    },
    async mounted() {
        this.isAgentActive();
        this.getTenants();
        this.getNotifications();
        this.store = useStore();
        this.value = this.languages.find(x => x.lang === 'en');
        this.text = this.value.title;
        this.flag = this.value.flag;
        this.userName = this.user.firstName;
        this.userEmail = this.user.emailAddress;
        if (!this.myStore.localTenant && this.user && this.user.tenants && this.user.tenants.length > 0) {
            this.myStore.localTenant = this.user.selectedTenant || this.user.tenants[0].Id;
        }

        this.initialTenant = myStore.localTenant;

        // socket io
        const { connectIO } = useSocketIO();
        await connectIO(this.userId);
    },
    unmounted() {
        //this.socket.off('connect_error');
    },
    methods: {
        ...mapActions('layout', {
            toggleWidgetSidebar: 'toggleWidgetSidebar',
        }),
        ...mapActions('appSearch', {
            setAppSearchValue: 'setValue',
        }),
        async getNotifications() {
            let result = await notificationService.getUnreadUserNotifications();
            this.$store.dispatch('toolBarNotification/clear');
            if (result?.result?.success) {
                let notify = result.data.notifications;

                for (let i = 0; i < notify.length; i++) {
                    let message = {
                        Icon: '',
                        HeaderText: notify[i].itemType,
                        BodyText: notify[i].message,
                        dateCreated: notify[i].dateCreated,
                    };
                    this.$store.dispatch('toolBarNotification/addRaw', message);
                }
            }
        },
        handleConnectivityChange(status) {
            this.onLine = status;
            this.$store.commit('offline/setOfflineStatus', !status);
        },
        async isAgentActive() {
            //TODO: add check on startup to see what last recorded entry was tyo set the initial state.

            try {
                let result = await agentService.getLatestAgentAction();
                if (_.isUndefined(result) || _.isEmpty(result.data.agentAction) || result.data.agentAction[0].action === 0) {
                    this.$store.commit('offline/setAgentStatus', false);
                    return false;
                }
                if (result && result.data.agentAction[0].action === 1) {
                    this.$store.commit('offline/setAgentStatus', true);
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                return false;
            }
        },
        /**
         * Toggle menu
         */

        /**
         * Toggle menu
         */
        toggleMenu() {
            document.querySelector('body').classList.toggle('sidebar-enable');
            this.$parent.toggleMenu();
        },
        initFullScreen() {
            document.body.classList.toggle('fullscreen-enable');
            this.isFullScreen = true;
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                this.isFullScreen = false;
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
            console.log('fullscreen', this.isFullScreen);
        },
        logoutUser() {
            this.logout();
            this.$router.go('/account/login');
        },

        async getTenants() {
            try {
                if (!_.isEmpty(this.user)) {
                    const { data } = await tenantService.getAllTenantDescendantsByUserId();

                    this.tenants = data.tenants;
                    this.tenantsNodes = data.tenantsArranged;
                    this.setUserTenants(this.tenants);
                } else {
                    console.log('User not logged in, cannot fetch tenants');
                }
            } catch (error) {
                console.log('Error loading topbar tenants.', error);
            }
        },
        closeTreeDropdown() {
            this.expandedKeys = {};
            document.querySelector('.dropdown-toggle').click();
        },
        async onNodeSelected(e) {
            try {
                let keyValue = e.Id;

                if (keyValue) {
                    await this.selectTenant(keyValue);
                    this.closeTreeDropdown();
                }
            } catch (e) {
                console.log('Invalid Tree Node', e);
            }
        },
        // onResetTenants() {
        //     //console.log(this.tenants, this.getDefaultTenantId());
        //     this.selectTenant(this.tenants.find((x) => x.Id === this.getDefaultTenantId()).name);
        //     this.closeTreeDropdown();
        //     this.showResetTenant = false;
        // },
        async selectTenant(id) {
            myStore.localTenant = id;

            //TODO: WORK on Id not name keep a note of which tenant was selected for use throughout the app
            if (!_.isEmpty(this.user)) {
                let selectedTenant = this.tenants.find(x => x.Id === id);
                this.$store.commit('dashboard/setTenant', selectedTenant.Id);
                await this.setSelectedTenantId(selectedTenant.Id);
                await this.saveUser();
            }

            return;
        },
        async appSearchChange() {
            // await appSearch.setValue('');
            await appSearch.setValue(this.appSearchValue);
            // await this.setAppSearchValue(this.appSearchValue);
            this.appSearchValue = '';
        },
    },
};
</script>
