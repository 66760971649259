import { createApp, h } from 'vue';
import BootstrapVue from 'bootstrap-vue-3';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { version } from '../package.json';
import vco from 'v-click-outside';
import VueApexCharts from 'vue3-apexcharts';
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar';
import { createI18n } from 'vue-i18n';
import DropZone from 'dropzone-vue';
import VueSignaturePad from 'vue3-signature-pad';

// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';

import CKEditor from '@ckeditor/ckeditor5-vue';
import VueGravatar from 'vue3-gravatar';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
// element-plus
import 'element-plus/theme-chalk/index.css';
import installElementPlus from './plugins/element';
//Geo server plugin
import vfmPlugin from 'vue-final-modal';
// Vue Attachments
import VueAttachment from 'vue-attachment';
// As a plugin
import VueGoodTablePlugin from 'vue-good-table-next';

// import geoServerSocket from './plugins/geoserver-socket'
import Simplebar from './components/SimpleBar';
import router from './router';
import App from './App.vue';
import axios from './helpers/api';
import DomainDetector from './plugins/domainDetector';
import store from '@/state/store';
import PrimeVue from 'primevue/config';
import Tree from 'primevue/tree';
import vTree from "vue3-tree";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Vue3Tour from 'vue3-tour';
import ToastService from 'primevue/toastservice';
import helpers from './helpers/custom';
//import Vue3Geolocation from 'vue3-geolocation';

import 'vue3-tour/dist/vue3-tour.css';

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css';

import 'simplebar/dist/simplebar.min.css';
import '@/assets/scss/app.scss';
// Sweet alerts
import 'sweetalert2/dist/sweetalert2.min.css';


// temporary fix for css file not pulling through, its a known issue: https://github.com/vueform/multiselect/issues/325.
// import '@vueform/multiselect/themes/default.css';
import './assets/multiselect-default.css';

import 'dropzone-vue/dist/dropzone-vue.common.css';

import 'vue-search-input/dist/styles.css';

//import Datepicker from '@vuepic/vue-datepicker';
import Datepicker from '@/components/DatePicker.vue';
import '@vuepic/vue-datepicker/dist/main.css';

import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import './registerServiceWorker';

import SearchInput from 'vue-search-input';
// import BpmnJS from '@phl/bp-js/dist/bp-navigated-viewer.production.min';
// import BpmnModeler from '@phl/bp-js';
// import BpmnViewer from '@phl/bp-js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { DateTime } from 'luxon';
import eventBus from './event-bus';

// Globally import grid layout plus
import { GridLayout, GridItem } from 'grid-layout-plus';

// load font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fab);
library.add(fas);
library.add(far);

// setup Localisation
const messages = { en: require('./locales/en.json') };
const app = createApp({
    render: () => h(App),
});

const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});
installElementPlus(app);
if (window.Cypress) {
    // Add `store` to the window object only when testing with Cypress
    window.__store__ = store;
}

app.use(router);
app.use(store);
app.use(i18n);
app.component('VueSlideBar', VueSlideBar);
app.use(BootstrapVue);
app.use(vco);
app.component('apexchart', VueApexCharts);
app.use(VueSweetalert2);
app.use(DomainDetector);
app.component('simplebar', Simplebar);
app.use(VueGoodTablePlugin);
app.component(DropZone);
app.use(VueGravatar);
app.use(VueAttachment);
app.use(vfmPlugin);
app.use(CKEditor);
app.use(PrimeVue);
app.use(Vue3Tour);
app.use(ToastService);
app.use(SearchInput);
app.use(Toastify);
app.component('Datepicker', Datepicker);
app.component('RecycleScroller', RecycleScroller);
app.component('font-awesome-icon', FontAwesomeIcon);

// app.config.globalProperties.$bpmnViewer = new BpmnViewer();
// app.config.globalProperties.$bpmnModeler = new BpmnModeler();
// app.config.globalProperties.$bpmnInstance = new BpmnJS();


//initiate global components for grid layout
app.component('GridLayout', GridLayout)
app.component('GridItem', GridItem)

app.component('Tree', Tree);
app.component('vTree', vTree)
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Button', Button);
app.component('Tag', Tag);

app.use(VueSignaturePad);
//app.use(Vue3Geolocation);

const token = localStorage.getItem('token');
axios.interceptors.request.use(
    config => {
        let tenantId = '';
        if (JSON.parse(localStorage.getItem('user'))) {
            tenantId = JSON.parse(localStorage.getItem('user')).selectedTenant || JSON.parse(localStorage.getItem('user')).tenants[0];
        }
        config.headers['x-tenant-id'] = `${tenantId}`;
        config.headers['x-ui-version'] = `${version}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const status = error.response.status || 500;
        switch (status) {
            case 401: {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('tenants');
                localStorage.removeItem('refreshToken');
                delete axios.defaults.headers.common.Authorization;
                if (window.location.href.indexOf('login') == -1) {
                    return router.go('/login');
                }
                break;
            }
            default: {
                return Promise.reject(error);
            }
        }
    },
);

//axios.defaults.headers.common.Authorization = `Bearer ${token}`;

app.config.globalProperties.$geoInterval = {};

app.config.globalProperties.$isAdmin = store.getters['user/isAdmin'];
app.config.globalProperties.$isLoanOfficer = store.getters['user/isLoanOfficer'];
app.config.globalProperties.$isCreditOfficer = store.getters['user/isCreditOfficer'];
app.config.globalProperties.$isTeamLeader = store.getters['user/isTeamLeader'];
app.config.globalProperties.$getUser = store.getters['user/getUser'];
app.config.globalProperties.emitter = eventBus;

//tracking map variables
// app.config.globalProperties.selectedAsset = window.selectedAsset;
// app.config.globalProperties.selectedAssetId = window.selectedAssetId;
// app.config.globalProperties.selectedDeviceType = window.selectedDeviceType;
// app.config.globalProperties.showAssetDetails = window.showAssetDetails;
// app.config.globalProperties.isAnimating = window.isAnimating;
// app.config.globalProperties.isUpdating = window.isUpdating;

app.config.globalProperties.$filters = {
    formatDateTime: helpers.formatDateTime,
    formatDateTimeISO: helpers.formatDateTimeISO,
    formateTimePast: helpers.formateTimePast,
    formatTimePastMoment: helpers.formatTimePastMoment,
    formatDecimal: helpers.formatDecimal,
    formatLatLong: helpers.formatLatLong,
};

// var retryGPS = true;
// var retryTimeout = 60000;
// var retryCount = 0;

router.isReady().then(() => {
    app.mount('#app');
});

//Leflet Edit
//import "leaflet-editable" ;
