import GeoServer from '../../helpers/geoserver/GeoServer';
import { geoDeviceService } from '../../services/geo.device.service';
import { geoAssetService } from '../../services/geo.asset.service';
import { cleanPositionsData } from '../../helpers/mapHelpers';
import pouch from '../../services/pouchDb';
import _ from 'lodash';
const TRACKING_MAP_SHOW_GEO_FENCE = 'tracking_map_show_geo_fence';
const MAX_ASSET_LIST_UPDATE = 1;
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// initial state
export const state = () => ({
    all: [],
    FastLookup: {},
    selected: '',
    selectedAsset: null,
    deviceDetails: null,
    selectedAssetDeviceType: 'Primary',
    showGeoFence: false,
    assetTripsReports: {},
    positions: {
        meta: null,
        data: [],
    },
    assetDetails: {
        show: false,
        tabs: [
            { id: 1, title: 'Asset Details', active: true },
            { id: 2, title: 'Alerts', active: false },
            { id: 3, title: 'Events', active: false },
            { id: 4, title: 'Trips', active: false },
            { id: 5, title: 'Info', active: false },
            { id: 6, title: 'Crash', active: false },
            { id: 7, title: 'Edit', active: false },
            { id: 8, title: 'Share', active: false },
        ],
    },
    directions: {
        waypoints: [],
        showDetails: false,
    },
});

// getters
export const getters = {
    getDevice: state => id => {
        const oDex = state.FastLookup[id];
        if (oDex) {
            let idx = oDex.index;
            return state.all[idx];
        }
        return null;
    },
    getDevicesCount: state => () => {
        // console.log("Looking for ",id,state.all.length)
        return state.all.length;
    },

    getSelectedDevice: state => () => {
        // console.log("Looking for ",id,state.all.length)
        return state.selected;
    },
    getDevicePosition: state => id => {
        const oDex = state.FastLookup[id];
        if (oDex && oDex.index) {
            let idx = oDex.index;
            return state.all[idx].coords;
        }
        return [];
    },
    //getAssetInfo: state => type => {
    //     return Object.values(state.selectedAsset.devices).find(device => device.devicetypename === type);
    //},
    getAssetDevice: state => () => {
        //debugger;
        let oReturnDevice = null;
        if (!state.selectedAsset || Object.keys(state.selectedAsset.devices).length == 0) return oReturnDevice;

        let type = state.selectedAssetDeviceType === 'Beacon' ? 'Bluetooth Transponder' : state.selectedAssetDeviceType;

        const aDeviceId = Object.keys(state.selectedAsset.devices);
        for (let index = 0; index < aDeviceId.length; index++) {
            const deviceID = aDeviceId[index];
            const device = state.selectedAsset.devices[deviceID];
            if (device.devicetypename === type && device.latitude !== null && device.longitude !== null) {
                oReturnDevice = device;
                break;
            }
        }
        if (oReturnDevice == null && aDeviceId.length > 0) {
            oReturnDevice = state.selectedAsset.devices[aDeviceId[0]];
        }
        return oReturnDevice;
    },
    getCurrentAssetDetailsTab: state => () => {
        return state.assetDetails.tabs.find(tab => tab.active === true);
    },
};

// actions
export const actions = {
    async setSelectedDevice({ state, commit, dispatch }, asset) {
        // clear any directions waypoints
        commit('setDirections', { waypoints: [] });
        console.log('Device Map Set Selected Asset', asset);
        if (!asset) {
            //debugger;
            return null;
        }
        const selectedAssetDetails = Object.freeze(JSON.parse(JSON.stringify(asset)));
        //debugger;
        if (selectedAssetDetails && selectedAssetDetails.devices) {
            //find newest device details
            //debugger;
            if (Object.keys(selectedAssetDetails.devices).length > 0) {
                const deviceListKey = Object.keys(selectedAssetDetails.devices);
                let lastDevice = null;
                for (let index = 0; index < deviceListKey.length; index++) {
                    const currentDevice = selectedAssetDetails.devices[deviceListKey[index]];
                    if (lastDevice == null) {
                        lastDevice = currentDevice;
                    } else {
                        if (new Date(currentDevice.deviceTime).getTime() > new Date(lastDevice.deviceTime).getTime()) {
                            lastDevice = currentDevice;
                        }
                    }
                }
                if (lastDevice == null) {
                    //debugger;
                    commit('setSelectedAssetDeviceType', 'Primary');
                } else {
                    lastDevice.devicetypename = lastDevice.devicetypename == 'Bluetooth Transponder' ? 'Beacon' : lastDevice.devicetypename;
                    commit('setSelectedAssetDeviceType', lastDevice.devicetypename);
                }
            } else {
                //debugger;
                commit('setSelectedAssetDeviceType', 'Primary');
            }
            const devices = Object.values(selectedAssetDetails.devices);
            if (Object.keys(devices).length > 0 && devices[Object.keys(devices)[0]].uniqueId) {
                commit('setSelectedAsset', selectedAssetDetails);
                return selectedAssetDetails;
            }
        } else {
            //debugger;
        }

        await dispatch('getAssetDevices', assetInfoId);
        setTimeout(
            function (assetInfoId) {
                dispatch('fetchAssetTripsReports', { assetId: assetInfoId, showZeros: true });
            }.bind(this),
            100,
            assetInfoId,
        );
        return selectedAssetDetails;
    },
    async setSelectedAsset({ state, commit, dispatch }, asset) {
        // clear any directions waypoints
        commit('setDirections', { waypoints: [] });
        if (!asset) {
            //debugger;
            return null;
        }
        const selectedAssetDetails = Object.freeze(JSON.parse(JSON.stringify(asset)));
        //debugger;
        if (selectedAssetDetails && selectedAssetDetails.devices) {
            //find newest device details
            //debugger;
            if (Object.keys(selectedAssetDetails.devices).length > 0) {
                const deviceListKey = Object.keys(selectedAssetDetails.devices);
                let lastDevice = null;
                for (let index = 0; index < deviceListKey.length; index++) {
                    const currentDevice = selectedAssetDetails.devices[deviceListKey[index]];
                    if (lastDevice == null) {
                        lastDevice = currentDevice;
                    } else {
                        if (new Date(currentDevice[4]).getTime() > new Date(lastDevice[4]).getTime()) {
                            lastDevice = currentDevice;
                        }
                    }
                }
                if (lastDevice == null) {
                    //debugger;
                    commit('setSelectedAssetDeviceType', 'Primary');
                } else {
                    lastDevice.devicetypename = lastDevice.devicetypename == 'Bluetooth Transponder' ? 'Beacon' : lastDevice.devicetypename;
                    commit('setSelectedAssetDeviceType', lastDevice.devicetypename);
                }
            } else {
                //debugger;
                commit('setSelectedAssetDeviceType', 'Primary');
            }
            const devices = Object.values(selectedAssetDetails.devices);
            if (Object.keys(devices).length > 0 && devices[Object.keys(devices)[0]].uniqueId) {
                commit('setSelectedAsset', selectedAssetDetails);
                return selectedAssetDetails;
            }
        } else {
            //debugger;
        }

        await dispatch('getAssetDevices', assetInfoId);
        setTimeout(
            function (assetInfoId) {
                dispatch('fetchAssetTripsReports', { assetId: assetInfoId, showZeros: true });
            }.bind(this),
            100,
            assetInfoId,
        );
        return selectedAssetDetails;
    },
    async getAssetDevices({ commit }, assetId) {
        /*
        const devices = await geoDeviceService.getAssetDevices(assetId);
        commit('setAssetDevicesDetails', [assetId, devices.data.assetDevices, devices.data.assets]);
        */
    },
    async pollAssetListUpdate({ commit }) {
        //const assetsListUpdate = await geoAssetService.getMapAssetLastDeviceTime(1, 500, 'lastUpdate', 'DESC', MAX_ASSET_LIST_UPDATE);
        //if (assetsListUpdate.data && assetsListUpdate.data.geoAssets) {
        //    for (let index = 0; index < assetsListUpdate.data.geoAssets.length; index++) {
        //        const asset = assetsListUpdate.data.geoAssets[index]
        //        commit('updateAssetPositionFromDB', [asset.Id, asset])
        //        await new Promise(resolve => setTimeout(resolve, 100))
        //    }
        //}
    },
    async getAllDevices({ commit }) {
        /*
        let allDevices = [];
        let allDetailRows = [];
        let page = 1;
        let size = 1000;

        let devices = await geoDeviceService.getAllDevices(page, size);
        if (devices.meta.paging.total > size) {
            allDevices.push(...devices.data.geoDevices);
            allDetailRows.push(...devices.data.DetailRows);
            let totalPages = Math.ceil(devices.meta.paging.total / size) + 1;

            for (let index = 2; index < totalPages; index++) {
                page = index;
                try {
                    let [d, f] = await Promise.all([
                        geoDeviceService.getAllDevices(page, size),
                        geoDeviceService.getAllDevices(page + 1, size),
                    ]);
                    //let devicesTemp = await geoDeviceService.getAllDevices(page, size);
                    index++;
                    if (d.data.geoDevices) {
                        allDevices.push(...d.data.geoDevices);
                        allDetailRows.push(...d.data.DetailRows);
                    }
                    if (f.data.geoDevices) {
                        allDevices.push(...f.data.geoDevices);
                        allDetailRows.push(...f.data.DetailRows);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        } else {
            allDevices.push(...devices.data.geoDevices);
            allDetailRows.push(...devices.data.DetailRows);
        }
        const lookup = _.groupBy(allDetailRows, 'assetInfoId');
        const SubData = _.groupBy(allDevices, 'Id');

        const newDeviceData = Object.values(SubData).map(x => {
            x = x[0];
            x.name = x.registrationNumber + ' - ' + x.make;
            const DeviceDetails = lookup[x.Id] || null;

            x.devices = {};

            if (DeviceDetails) {
                const objsTemp = _.groupBy(DeviceDetails, 'deviceId');
                const aKeys = Object.keys(objsTemp);
                for (let index = 0; index < aKeys.length; index++) {
                    const key = aKeys[index];
                    const device = objsTemp[key];
                    const lastDevice = device[device.length - 1];
                    if (lastDevice.telemetry) {
                        lastDevice.attributes = [...lastDevice.telemetry]
                    } else {
                        lastDevice.telemetry = {};
                        lastDevice.attributes = [];
                    }
                    lastDevice.telemetry = { course: device.course }
                    for (let index = 0; index < lastDevice.attributes.length; index++) {
                        const at = lastDevice.attributes[index];
                        lastDevice.telemetry[at.t] = at.v
                    }
                    x.devices[lastDevice.deviceId] = lastDevice;
                }
            }
            x.latitude = (Number(x.latitude) || 0) == NaN ? 0 : Number(x.latitude) || 0;
            x.longitude = (Number(x.longitude) || 0) == NaN ? 0 : Number(x.longitude) || 0;

            if (x.latitude && x.longitude) {
                x.coords = [x.latitude, x.longitude];
            } else {
                x.coords = [];
            }
            x.icon = x.icon || 'car';
            return x;
        });

        commit('setDevices', newDeviceData);
        return newDeviceData;
        */
    },
    haveDevices({ getters }) {
        //return getters.getDevicesCount() > 0 ? true : false;
    },
    getDevicePosition({ getters }, id) {
        //return getters.getDevicePosition(id);
    },
    getSelectedDevice({ getters }) {
        //return getters.getSelectedDevice();
    },
    getDevice({ getters }, deviceId) {
        //return getters.getDevice(deviceId);
    },
    async fetchAssetTripsReports({ state, commit }, payload) {
        //console.log('fetchAssetTripsReports', payload);
        // if data with same payload was cached, return cached data, otherwise pull from api.
        const assetId = window.selectedAsset.Id;
        const { startDate, endDate, showZeros, page, size, sortField, sortOrder } = payload;
        const cached = state.assetTripsReports[assetId];
        if (!cached || JSON.stringify(payload) !== JSON.stringify(cached.payload)) {
            const { data } = await geoAssetService.getAssetTripReports(
                assetId,
                startDate,
                endDate,
                showZeros,
                page,
                size,
                sortField,
                sortOrder,
            );
            if (data.data.trips.length > 0) {
                commit('setAssetTripsReports', { assetId, payload, result: data });
            }
            return data;
        } else {
            return cached.result;
        }

    },
    async fetchAssetTrips({ state, commit }, payload) {
        /*
        const { data } = await geoAssetService.getAssetTrips(payload);
        return data;
        */
    },
    async fetchAssetPositions({ commit }, payload) {
        /*
        const { deviceId, startDate, endDate, page, size, sortField, sortOrder, search } = payload;
        const { data } = await geoDeviceService.getDeviceFullPositionReport(
            deviceId,
            startDate,
            endDate,
            page,
            size,
            sortField,
            sortOrder,
            search,
        );
        const positions = cleanPositionsData(data.data.position);
        commit('setAssetPositions', { meta: data.meta, data: positions });
        */
    },
    async fetchDeviceDetails({ commit }, deviceId) {
        /*
        if (deviceId) {
            const { data } = await geoDeviceService.getDeviceById(deviceId);
            //debugger;
            commit('setDeviceDetails', data.device);
        }
        */
    },
};

// mutations
export const mutations = {
    setDevices(state, devices) {
        state.all = devices;
        let lookup = {};
        devices.forEach((vehicle, index) => {
            lookup[vehicle.Id] = {
                index: index,
            };
        });
        state.FastLookup = Object.freeze(lookup);
    },
    setAssetDevicesDetails(state, aAssetDevice) {
        if (aAssetDevice[1].length == 0) {
            return;
        }
        const oDex = state.FastLookup[aAssetDevice[0]];
        let idx = oDex.index;

        const AssetDetails = state.all[idx];
        if (AssetDetails && !AssetDetails.devices) {
            AssetDetails.devices = {};
        }
        if (AssetDetails) {
            for (let index = 0; index < aAssetDevice[1].length; index++) {
                const device = aAssetDevice[1][index];
                if (AssetDetails.devices[device.deviceId]) {
                    AssetDetails.devices[device.deviceId] = Object.assign(AssetDetails.devices[device.deviceId], device);
                } else {
                    AssetDetails.devices[device.deviceId] = device;
                }
                AssetDetails.devices[device.deviceId].coords = [device.latitude, device.longitude];

            }
            state.all[idx].features = aAssetDevice[2].features;
            state.all[idx].photos = aAssetDevice[2].photos;
            state.all[idx].engineHours = aAssetDevice[2].engineHours;
            state.all[idx].lastOdometer = aAssetDevice[2].lastOdometer;

            state.selectedAsset = AssetDetails;
        }
    },
    updateAssetPositionFromDB(state, args) {
        const AssetToUpdate = args[1];
        if (state.all.length > 0) {
            const oDex = state.FastLookup[args[0]];
            if (oDex && oDex.index) {
                let idx = oDex.index
                const asset = state.all[idx];
                if (asset.lastUpdate && new Date(asset.lastUpdate).getTime() < new Date(AssetToUpdate.lastUpdate).getTime()) {
                    asset.lastUpdate = AssetToUpdate.lastUpdate;
                    asset.status = AssetToUpdate.status;
                    asset.latitude = AssetToUpdate.latitude;
                    asset.longitude = AssetToUpdate.longitude;
                    asset.address = AssetToUpdate.address;
                    state.all[idx] = asset;
                }
            }
        } else {
            console.error('No Data in state');
        }
    },
    async updateAssetPositions(state, args) {
        if (state.all.length > 0) {
            let selectIndex = args.findIndex(x => x.Id == state.selectedAsset.Id);
            if (selectIndex > -1) {
                const AssetToUpdate = args[selectIndex];
                commit('updateAssetPosition', [AssetToUpdate.Id, AssetToUpdate]);
            }
        }
    },
    updateAssetPosition(state, args) {
        if (state.all.length > 0) {
            //debugger;
            //console.log("Update DB with ",args[0]);
            const oDex = state.FastLookup[args[0]];
            if (oDex && oDex.index) {
                let idx = oDex.index;
                const AssetToUpdate = args[1];
                state.all[idx] = AssetToUpdate;
                if (state.selectedAsset && state.selectedAsset.Id && state.selectedAsset.Id == AssetToUpdate.Id) {
                    state.selectedAsset = AssetToUpdate;
                }
            } //else {
            //  debugger;
            //}
        } else {
            console.error('No Data in state');
        }
    },
    setSelectedDevice(state, asset) {
        // set selected asset id
        state.selected = asset.Id;
        state.selectedAsset = asset;
    },
    setSelectedAsset(state, asset) {
        // set selected asset id
        state.selected = asset.Id;
        state.selectedAsset = asset;
    },
    setShowGeoFence(state, show) {
        state.showGeoFence = show;
        localStorage.setItem(TRACKING_MAP_SHOW_GEO_FENCE, JSON.stringify({ geoFence: show }));
    },
    getShowGeoFence(state) {
        let show = localStorage.getItem(TRACKING_MAP_SHOW_GEO_FENCE);
        if (show) {
            try {
                let data = JSON.parse(show);
                state.showGeoFence = data.geoFence;
            } catch (e) {
                state.showGeoFence = true;
                localStorage.setItem(TRACKING_MAP_SHOW_GEO_FENCE, JSON.stringify({ geoFence: true }));
            }
        } else {
            state.showGeoFence = true;
            localStorage.setItem(TRACKING_MAP_SHOW_GEO_FENCE, JSON.stringify({ geoFence: true }));
        }
    },
    setShowAssetDetails(state, show) {
        state.assetDetails.show = show;
    },
    setAssetTripsReports(state, data) {
        const { assetId, payload, result } = data;
        state.assetTripsReports[assetId] = {
            payload,
            result,
        };
    },
    setAssetInfo(state, info) {
        state.assetInfo.sim = 'N/A'; // TODO: field to added to api
        state.assetInfo.status = info.status;
        state.assetInfo.imei = info.imei;
    },
    setAssetPositions(state, positions) {
        state.positions = positions;
    },
    setAssetDetailsShow(state, show) {
        state.assetDetails.show = show;
    },
    setAssetDetailsTab(state, tabId) {
        state.assetDetails.tabs = state.assetDetails.tabs.map(tab => {
            if (tab.id === tabId) {
                return {
                    ...tab,
                    active: true,
                };
            } else {
                return {
                    ...tab,
                    active: false,
                };
            }
        });
    },
    setSelectedAssetDeviceType(state, type) {
        state.selectedAssetDeviceType = type;
        // await dispatch('fetchDeviceDetails', 'add8e8a7-7e31-430f-be7e-b0e8d32f354a');
    },
    setSelectedAsset(state, asset) {
        state.selected = asset.Id;
        state.selectedAsset = asset;
    },
    setDeviceDetails(state, details) {
        state.deviceDetails = details;
    },
    setDirections(state, directions) {
        state.directions = directions;
    },
};
