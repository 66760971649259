import { createRouter, createWebHistory } from 'vue-router';
import { useUserAuth } from '@/composables/userAuth.js';
import routes from './routes';
import store from '@/state/store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return {
            x: 0,
            y: 0,
        };
    },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
    if (!navigator.onLine && !routeTo.meta.offline) {
        console.log('Redirect to offline page');
        return redirectToOffline();
    }

    // Check if auth is required on this route
    if (
        routeTo.name !== 'login' &&
        routeTo.name !== 'forgot-password' &&
        routeTo.name !== 'reset-password' &&
        routeTo.name !== 'account-verification'
    ) {
        await tokenVerification();
    }

    // (including nested routes).
    const authRequired = routeTo.matched.some(route => route.meta.authRequired);
    // If auth isn't required for the route, just continue.
    if (!authRequired) return next();

    const isLoggedIn = await store.getters['auth/loggedIn'];
    if (isLoggedIn) { 
        // check permissions
        const routePermissions = routeTo.meta.allowedPermissions || ['ALL'];
        const notAllowedRedirect = routeTo.meta.notAllowedRedirect || 'not-authorized';
        const user = JSON.parse(localStorage.getItem('user'));
        user.permissions?.push.apply(
            user.permissions,
            user.rolepermissions || [],
        );
        const userPermissions = user.permissions;
        // validate only if route has specific permissions
        if (
            routePermissions && routePermissions.length &&
            routePermissions.length >= 1 &&
            routePermissions[0] !== 'ALL' &&
            routePermissions[0] !== 'ANY'
        ) { 
            const userAuth = useUserAuth();
            const userHasPermissions = routePermissions.some(auth=>userAuth.hasPermission(auth));            
            if (!userHasPermissions) {
                if (notAllowedRedirect==='not-authorized'){
                    redirectToNotAuthorized();
                }else{
                    return next({name: notAllowedRedirect});
                } 
            }
        }

        // TODO: Validate Token
        return next();
    }
    redirectToLogin();

    function redirectToLogin() {
        // Pass the original route to the login component
        return next({
            name: 'login',
            query: {
                redirectFrom: routeTo.fullPath,
            },
        });
    }

    function redirectToOffline() {
        // Pass the original route to the login component
        return next({
            name: 'offline',
        });
    }

    function redirectToNotAuthorized() {
        return next({
            name: 'not-authorized',
            query: {
                redirectFrom: routeTo.fullPath,
            },
        });
    }
});

const tokenVerification = async () => {
    const tokenKey = localStorage.getItem('token');
    if (!tokenKey) {
        router.push('/login');
    }
};

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

export default router;
