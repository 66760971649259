import { userService } from '../../services/user.service';
import _ from 'lodash';

export const state = {
    users: [],
    gpsCoords: {},
};

export const getters = {
    getUsers: state => {
        return state.users;
    },
    getUser: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        return user;
    },
    isAdmin: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            if (
                user.roles?.find(
                    role =>
                        role.name.toLowerCase() === 'global administrator' ||
                        role.name.toLowerCase() === 'administrator' ||
                        role.name.toLowerCase() === 'admin',
                )
            ) {
                return true;
            }
        }
        return false;
    },
    isGlobalAdmin: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            if (
                user.roles?.find(
                    role => role.name.toLowerCase() === 'global administrator',
                )
            ) {
                return true;
            }
        }
        return false;
    },
    isLoanOfficer: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            if (
                user.roles?.find(
                    role => role.name.toLowerCase() === 'loan officer',
                )
            ) {
                return true;
            }
        }
        return false;
    },
    isCreditOfficer: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            if (
                user.roles?.find(
                    role => role.name.toLowerCase() === 'credit officer',
                )
            ) {
                return true;
            }
        }
        return false;
    },
    isTeamLeader: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            if (
                user.roles?.find(
                    role => role.name.toLowerCase() === 'team leader',
                )
            ) {
                return true;
            }
        }
        return false;
    },
    isBranchManager: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            if (
                user.roles?.find(
                    role => role.name.toLowerCase() === 'branch manager',
                )
            ) {
                return true;
            }
        }
        return false;
    },
};

export const actions = {
    async fetchUsers({ commit }) {
        const users = await userService.getAll();
        commit('setUsers', users);
    },
};

export const mutations = {
    setUsers: (state, users) => {
        state.users = users;
    },
    setLastGpsCoordinates: (state, gpsCoords) => {
        state.gpsCoords = gpsCoords;
    },
};
