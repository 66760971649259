<template>
  <Datepicker :placeholder="placeholder" v-model="state.date" :format="format" :previewFormat="previewFormat"
    :autoPosition="autoPosition" :enableTimePicker="enableTimePicker" :range="range" @closed="dateRangeChange"
    @cleared="cleared" ref="dp">
     
  </Datepicker>
</template>
<script setup>
import { DateTime } from 'luxon';
import { reactive, computed, watch, onMounted } from 'vue'
import Datepicker from '@vuepic/vue-datepicker';
const emit = defineEmits(['update:modelValue', 'closed', 'cleared'])
const props = defineProps({
  modelValue: Array,
  placeholder: {
    type: String,
    default: 'Filter by date.'
  },
  format: {
    type: String,
    default: 'yyyy/MM/dd, hh:mm:ss'
  },
  previewFormat: {
    type: String,
    default: 'yyyy/MM/dd,'
  },
  autoPosition: {
    type: Boolean,
    default: true
  },
  partialRange: {
    type: Boolean,
    default: true
  },
  enableTimePicker: {
    type: Boolean,
    default: true
  },
  range: {
    type: Boolean,
    default: true
  },
});
const state = reactive({
  date: [DateTime.fromJSDate(new Date(DateTime.now().startOf('week').toFormat('yyyy/MM/dd HH:mm:ss.SSS'))),
  DateTime.fromJSDate(new Date(DateTime.now().endOf('week').toFormat('yyyy/MM/dd HH:mm:ss.SSS')))]
});
const modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  }
});
watch(modelValue, (value) => {
  if (value) {
    state.date = value;
  }
});
if (modelValue.value) {
  state.date = modelValue.value;
}
onMounted(() => {
  if (modelValue.value === null || modelValue.value === undefined) {
    modelValue.value = state.date;
  } else {
    state.date = modelValue.value;
  }
})
const dateRangeChange = () => { 
  modelValue.value = state.date;
  emit('closed', state.date);
}
const cleared = () => {
  emit('cleared');
}
 
</script> 