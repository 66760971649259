import { geoDeviceService } from '@/services/geo.device.service';
import { geoAssetService } from '@/services/geo.asset.service';
// import GeoServer from '../../helpers/geoserver/GeoServer';

// function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

// initial state
export const state = () => ({
    all: [],
    HardwareTypes: [],
    lastSelectedHardwareTypes: 'ALL',
    DeviceTypes: [],
    lastSelectedDeviceTypes:'ALL'
});

// getters
export const getters = {
    getDevice: state => id => {
        // console.log("Looking for ",id,state.all.length)
        return state.all.find(device => device.id == id);
    },
};

// actions
export const actions = {
    async getHardwareTypes({ state, commit }) {
        if (state.HardwareTypes.length == 0) {
            let response = await geoDeviceService.getHardwareTypes();
            //debugger;
            if (response && response.data.deviceHardware && response.data.deviceHardware.length > 0) {
                commit('setHardwareTypes', response.data.deviceHardware);
                return response.data.deviceHardware;
            }
        }
        return state.HardwareTypes;
    },
    async getDeviceTypes({ state, commit }) {
        if (state.DeviceTypes.length == 0) {
            let response = await geoAssetService.getDeviceTypes();
            //debugger;
            if (response && response.data.deviceTypes && response.data.deviceTypes.length > 0) {
                commit('setDeviceTypes', response.data.deviceTypes);
                return response.data.deviceTypes;
            }
        }
        return state.DeviceTypes;
    },
    async getAllDevices({ commit }) {
        //const devices = await geoDeviceService.getAllDevices();
        //const { geoDevices } = devices.data;
        //commit('setDevices', geoDevices);
        return;
    },
    async getDevicesCash({ commit, getters }, devicesID) {
        const dev = getters.getDevice(devicesID);
        // console.log(" getDevicesCash ",dev);
        //if (!dev) {
        //    const devDB = await geoDeviceService.getDeviceById(devicesID);
        //    if (devDB && devDB.id) {
        //        commit('addDevices', [devDB]);
        //    }
        //    return devDB;
        //}
        return dev;
    },
    async createDevice({ commit }, newDeviceObj) {
        //const device = await geoDeviceService.createDevice(newDeviceObj);
        //if (device && device.id) {
        //    commit('addDevices', [device]);
        // }
    },
    async updateDeviceAsync({ commit }, updateDeviceObj) {
        //const device = await geoDeviceService.updateDevice(
        //    updateDeviceObj.id,
        //    updateDeviceObj,
        //);
        //commit('updateDevice', [device, updateDeviceObj.id]);
    },
    async deleteDeviceAsync({ commit }, updateDeviceObj) {
        //const device = await geoDeviceService.deleteDevice(
        //    updateDeviceObj.id,
        // updateDeviceObj,
        //);
        //commit('deleteDevice', [device, updateDeviceObj.id]);
    },
    // TODO: THESE MAY NEED TO BE MOVED TO REST
    async sendCommandAsync(...[, sendCommandObj]) {
        //const deviceObj = sendCommandObj[0];
        //const commandObj = sendCommandObj[1];
        //await geoDeviceService.sendCommand(deviceObj.id, commandObj.id, commandObj);
    },
    async linkGeoFenceToDeviceAsync(...[, linkGeoFenceObj]) {
        //const deviceObjId = linkGeoFenceObj[0];
        //const geoFenceId = linkGeoFenceObj[1];
        //await geoDeviceService.linkGeoFenceToDevice(deviceObjId, geoFenceId);
    },
    async unlinkGeoFenceFromDeviceAsync(...[, unlinkGeoFenceObj]) {
        //const deviceObjId = unlinkGeoFenceObj[0];
        //const geoFenceId = unlinkGeoFenceObj[1];
        //await geoDeviceService.unlinkGeoFenceFromDevice(deviceObjId, geoFenceId);
    },
};

// mutations
export const mutations = {
    setDevices(state, devices) {
        state.all = Object.freeze(devices);
    },
    setHardwareTypes(state, HardwareTypes) {
        state.HardwareTypes = Object.freeze(HardwareTypes);
    },
    setSelectedHardwareTypes(state, HardwareTypes) {
        state.lastSelectedHardwareTypes = HardwareTypes;
    },
    setDeviceTypes(state, DeviceTypes) {
        state.DeviceTypes = Object.freeze(DeviceTypes);
    },
    setSelectedDeviceTypes(state, DeviceTypes) {
        state.lastSelectedDeviceTypes = DeviceTypes;
    },
    addDevices(state, devices) {
        devices.forEach(d => {
            if (!state.all.find(device => device.id == d.id)) {
                state.all.push(d);
            }
        });
    },
    updateDevicePosition(state, args) {
        let idx = state.all.findIndex(device => device.id == args[0]);
        if (idx != -1) {
            state.all[idx].positionId = args[1];
            state.all[idx].position = args[2];
        }
    },
    updateDevice(state, args) {
        const idx = state.all.findIndex(device => device.id == args[1]);
        if (idx != -1) {
            state.all[idx] = args[0];
        } else {
            state.all.push(args[0]);
        }
    },
    deleteDevice(state, args) {
        // console.debug(state.all);
        const idx = state.all.findIndex(device => device.id == args[1]);
        if (idx != -1) {
            state.all.splice(idx, 1);
        }
    },
    // decrementProductInventory (state, { id }) {
    //   const product = state.all.find(product => product.id === id)
    //   product.inventory--
    // }
};

// export default {
//   namespaced: false,
//   state,
//   getters,
//   actions,
//   mutations
// }
