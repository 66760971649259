import moment from 'moment';

export const NotificationEventTypes = [
    { text: 'Geofence Enter', value: 'geofenceEnter', severity: 'alarm', isViolation: false, isAlert: true },
    { text: 'Geofence Exit', value: 'geofenceExit', severity: 'alarm', isViolation: false, isAlert: true },
    { text: 'Over speed', value: 'overspeed', severity: 'alarm', isViolation: false, isAlert: true },
    { text: 'Crash', value: 'crash', severity: 'alarm', isViolation: true, isAlert: false },
    { text: 'Accident', value: 'accident', severity: 'alarm', isViolation: true, isAlert: false },
    { text: 'Hard Braking', value: 'hardBraking', severity: 'alarm', isViolation: true, isAlert: false },
    { text: 'Hard Cornering', value: 'hardCornering', severity: 'alarm', isViolation: true, isAlert: false },
    { text: 'GPS Antenna Cut', value: 'gpsAntennaCut', severity: 'alarm', isViolation: true, isAlert: false },
    { text: 'Ignition on', value: 'ignition on', severity: 'event', isViolation: true, isAlert: false },
    { text: 'Ignition off', value: 'ignition off', severity: 'event', isViolation: true, isAlert: false },
    { text: 'Tampering', value: 'tampering', severity: 'event', isViolation: true, isAlert: false },
    { text: 'Main Power On', value: 'battery_plugged', severity: 'event', isViolation: false, isAlert: true },
    { text: 'Main Power Off', value: 'battery_unplugged', severity: 'event', isViolation: false, isAlert: true },
];

export function findExtBattery(position) {
    let BattValue = getDeviceAttribute(position.attributes, 'power');
    if (BattValue) {
        return Number(BattValue || 0) / 1000;
    }
    return 0;
}
export function findBattery(position) {
    let BattValue = getDeviceAttribute(position.attributes, 'battery');
    if (BattValue) {
        return Number(BattValue || 0) / 1000;
    }
    return 0;
}
export function getDeviceAttribute(attributes, type) {
    if (!attributes) {
        //console.log('No attributes');
        return null;
    }
    let attribute = attributes.find(atr => atr.type == type || atr.t == type);
    if (!attribute) {
        return null;
    }

    return attribute.value ? attribute.value : attribute.v;
}
export function getDeviceTelemetry(telemetry, type) {
    if (!telemetry) return '';
    if (!telemetry[type]) {
        return '';
    }
    return telemetry[type];
}
function getProtocol(protocol) {
    switch (protocol) {
        case 'concox': {
            return 'GPS';
        }
        case 'concox_lbs': {
            return 'LBS';
        }
        case 'concox_htbs': {
            return 'HTBS';
        }
        default: {
            return 'GPS';
        }
    }
}
export function cleanPositionsData(positions) {
    let lastIgnition = null;
    return positions.map((x, index, arr) => {
        x.Geo = parseFloat(x.latitude).toFixed(9) + ',' + parseFloat(x.longitude).toFixed(9);
        x.event = 'Position Report';
        x.protocol = getProtocol(x.protocol);
        if (lastIgnition != getDeviceAttribute(x.attributes, 'ignition')) {
            if (getDeviceAttribute(x.attributes) != null) {
                lastIgnition = getDeviceAttribute(x.attributes, 'ignition');
            }
            if (getDeviceAttribute(x.attributes, 'ignition') == '1') {
                x.event = 'Ignition On';
            } else {
                x.event = 'Ignition Off';
            }
        }
        if (getDeviceAttribute(x.attributes, 'alarm')) {
            x.event = 'Alarm ' + getDeviceAttribute(x.attributes, 'alarm');
        }
        x.odometer = getDeviceAttribute(x.attributes, 'odometer');
        x.Satellites = getDeviceAttribute(x.attributes, 'sat');
        return x;
    });
}
export function isViolation(value) {
    const violations = NotificationEventTypes.filter(x => x.isViolation).map(x => x.value);

    return violations.includes(value);
}
export function isAlert(value) {
    const alerts = NotificationEventTypes.filter(x => x.isAlert).map(x => x.value);
    return alerts.includes(value);
}
export function formatEngineHours(seconds) {
    if(seconds){
        return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format('HH:mm');
    }
    return '00:00';
    
}
export function formatOdometer(Odometer) {
    if (Odometer) {
        return Number(Number(Odometer || 0) / 1000).toFixed(2);
    }
    return '0.00';
}
