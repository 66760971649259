import { io } from 'socket.io-client';
import Toastify from 'toastify-js';
import { useStore } from 'vuex';
import _ from 'lodash';
// HACK : this is a hack to get the store into the socket.io callback
var _store = null;
/**
 * Deals with socket call backs from the server
 *
 */
export function useSocketIO() {
     
    const store = useStore();
    if(store){
        _store = store;
    }
    let user = {};

    if (localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user') || {});
    }
     
    const socket = io(process.env.VUE_APP_WEBSOCKET_URL);
    socket.store = store;
    const connectIO = userID => {
        if (navigator.onLine) {
            socket.auth = { userID: userID };
            socket.connect();
        }
    };

    socket.on('session', ({ sessionID, userID }) => {
        // attach the session ID to the next reconnection attempts
        socket.auth.sessionID = sessionID;
        // store it in the localStorage
        if (localStorage.getItem('user') != null) {
            user = JSON.parse(localStorage.getItem('user') || {});
            user.SessionID = sessionID;
            localStorage.setItem('user', JSON.stringify(user));
        }
        // save the ID of the user
        socket.userID = userID;
        //console.log('Socket Connected:', sessionID, userID);
    });

    socket.on('connect_error', err => {
        console.log('Socket Connect Error', err);
    });

    // check to see if receiving notifications from the server
    socket.on('notification', data => {
        // Decode Notification data and Add Notification to notification bar
        //console.log('Notification Received', data);

        if (!_.isUndefined(data) && data.notification) {
            let notification = data.notification;
            let message = {
                Icon: notification.itemType,
                HeaderText: notification.itemType,
                BodyText: notification.message,
                dateCreated: notification.dateCreated,
            };
            //console.log('message',message);
            store.dispatch('toolBarNotification/addRaw', message);
        }
    });

    socket.on('reportComplete', data => {
        let type = 'error';
        let heading = 'Report';
        let body = 'There was a problem generating report';
        let icon = '<i class="uil uil-file-download-alt me-1"></i>';
         
        if (data.success) {
            type = 'success';
            heading = 'Report generated successfully';
            body = `${icon}<a target="_blank" href="${process.env.VUE_APP_API_URL}/${data.filePath}">${data.reportName}</a>`;
        } else {
            type = 'error';
            heading = data.heading || 'Report generated with Errors';
            body =
                data.body || '<i class="uil uil-file-download-alt me-1"></i>';
        }
        let onClick = null;
        if(data.filePath && data.filePath!==''){
            onClick = () => {
                window.open(
                    `${process.env.VUE_APP_API_URL}/${data.filePath}`,
                    '_blank',
                );
            }
            const autoOpen = (URL) => {                
                window.open(
                    `${process.env.VUE_APP_API_URL}/${URL}`,
                    '_blank',
                );
            } 
            setTimeout(autoOpen,  1000, data.filePath);
        }
          
        Toastify({ className: type, text: heading, onClick:onClick,style: {
            background: 'linear-gradient(to right, #00b09b, #96c93d)',
        } }).showToast();
         
        
        _store.dispatch('reportSystem/reportComplete', {...data, url: `${process.env.VUE_APP_API_URL}/${data.filePath}` });
        // remove in-progress spinner (if user is on reports page)
        const reportsTable = document.querySelector('.reports-table');
        if (reportsTable) {
            const btnInProgress = reportsTable.querySelectorAll(
                '.btn-generate-report.in-progress',
            );
            btnInProgress.forEach(btn => btn.classList.remove('in-progress'));
        }
    });

    socket.on('csvReportComplete', data => {
        let type = 'error';
        let heading = 'CSV Report';
        let body = 'There was a problem generating report';
        let icon = '<i class="uil uil-file-download-alt me-1"></i>';
        //console.log('socket',data);
        if (data.success) {
            type = 'success';
            heading = 'CSV Report generated successfully';
            body = `${icon}<a target="_blank" href="${process.env.VUE_APP_API_URL}/${data.filePath}">${data.reportName}</a>`;
        } else {
            type = 'error';
            heading = data.heading || 'Report generated successfully';
            body =
                data.body || '<i class="uil uil-file-download-alt me-1"></i>';
        }
        const onClick = () => {
            window.open(
                `${process.env.VUE_APP_API_URL}/${data.filePath}`,
                '_blank',
            );
        }
        
        Toastify({ className: type, text: heading, onClick:onClick,style: {
            background: 'linear-gradient(to right, #00b09b, #96c93d)',
        } }).showToast();
        
    }); 

    const generateReport = data => {
        if (navigator.onLine) {
            socket.emit('generateReport', data);
        }
    };
    /**
     * call websocket to generate report
     * @param {uuid} reportId
     * @param {array} parameters [{name: 'value'}]
     * @param {any} exportFormat csv,xlsx,pdf
     * @returns {any}
     */
    function generateReportV2(reportId,parameters,exportFormat){
        if (navigator.onLine) {
            socket.emit('runReportV2', {reportId,parameters,exportFormat});
        }
    };

    const generateCSVReport = data => {
        if (navigator.onLine) {
            socket.emit('generateCSVReport', data);
        }
    };

    return {
        connectIO,
        generateReport,
        generateCSVReport,
        generateReportV2
    };
}
