import axios from '../helpers/api';
import { DateTime } from 'luxon';
export default {
    /* Commands */
    async getCommands() {
        const res = await axios.get(`/api/geo/commands/command/all`);

        return res.data;
    },
    async getCommandById(CommandId) {
        const res = await axios.get(`/api/geo/commands/${CommandId}`);
        return res.data;
    },
    async getByDeviceID(deviceID) {
        const res = await axios.get(`/api/geo/commands/device/${deviceID}`);
        return res.data;
    },
    async deleteCommand(CommandId, CommandObj) {
        const res = await axios.delete(`/api/geo/commands/${CommandId}`, CommandObj);

        return res.data;
    },
    async updateCommand(CommandId, CommandObj) {
        const res = await axios.put(`/api/geo/commands/${CommandId}`, CommandObj);
        return res.data;
    },
    async createCommand(CommandObj) {
        CommandObj.type = 'command';
        const res = await axios.post(`/api/geo/commands`, CommandObj);
        return res.data;
    },
    async getAllCommands() {
        const res = await axios.get(`/api/geo/commands/command/all`);
        return res.data;
    },
    async getByDeviceHardwareID(deviceHardwareId, page = 1, size = 10000, sortField, sortOrder, search) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        search = search.toUpperCase().trim();

        const res = await axios.get(
            `/api/geo/commands/deviceHardware/${deviceHardwareId}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    },
    async getCommandReport(startDate, endDate, page, size, sortField = 'deviceTime', sortOrder = 'ASC', search = '%%') {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'deviceTime';
        sortOrder = sortOrder || 'ASC';
        startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
        endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
        if (navigator.onLine) {
            try {
                const res = await axios.get(
                    `/api/geo/commands/reports/commands/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
                );
                return res.data;
            } catch (error) {
                console.debug(error);
                return error;
            }
        } else {
            //ToDo
        }
    },
};
